import React, { Component } from 'react';

import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import AdministrationInput from '@components/administration/crud/AdministrationInput';
import ConstraintsInput from '@components/constraint/crud/ConstraintsInput';
import ContactDetailsInput from '@components/general/crud/ContactDetailsInput';
import ContactPersonsInput from '@components/general/crud/ContactPersonsInput';
import FlatIdentifierInput from '@components/general/crud/FlatIdentifierInput';
import LocationsInput from '@components/location/crud/LocationsInput';

import AvatarPicker from '@uicomponents/AvatarPicker';
import FormInput from '@uiinputs/FormInput';
import LanguageSelectorInput from '@uiinputs/LanguageSelectorInput';

import Association from '@models/general/Association';

import { activateInputs, deactivateInputs } from '@utils/formUtils';
import { base64ToBlob } from '@utils/imageUtils';

export default class BusinessForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();

    this.state = {
      logo: this.props.formBusiness?.logo?.url || '/images/business.svg',
      logoPicker: false,
      contactPerson: [],
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit, formBusiness, setHasChanged } = this.props;

    onSubmit && onSubmit(formBusiness);
    setHasChanged(false);
  }

  render() {
    const { t, uploadFile, onChange, formBusiness, industries, platform, setHasChanged } =
      this.props;
    const { logoPicker, logo } = this.state;

    return (
      <div
        className="step-form"
        key={`${formBusiness.id}-${formBusiness.versionNumber}-${formBusiness.updatedAt}`}
      >
        <AvatarPicker
          {...this.props}
          title={t('form.uploadLogo')}
          active={logoPicker}
          handleSubmit={(croppedImage) => {
            this.setState({
              logo: croppedImage,
              logoPicker: !logoPicker,
            });
            uploadFile(base64ToBlob(croppedImage), 'files').then((formBusinessLogo) => {
              const newBusiness = { ...formBusiness };
              newBusiness.logo = formBusinessLogo.file.id;
              onChange('formBusiness', newBusiness);
            });
          }}
          onClick={() => this.setState({ logoPicker: !logoPicker })}
          onClose={() => this.setState({ logoPicker: !logoPicker })}
        />
        <form className="form active" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div className="input-group no-margin-top">
              <img
                className="profile-image"
                src={logo || formBusiness.logo?.url}
                alt="formBusiness logo"
              />
              <label
                className="picture"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    logoPicker: !logoPicker,
                  });
                }}
              >
                {t('form.label.chooseLogo')}
              </label>
              <div>
                <FormInput
                  type="text"
                  setHasChanged={setHasChanged}
                  label="form.label.name"
                  required={true}
                  value={formBusiness.name}
                  onChange={(event) => {
                    const newBusiness = { ...formBusiness };
                    newBusiness.name = event.target.value;

                    onChange('formBusiness', newBusiness, event);
                  }}
                />
                {this.validator.message(t('form.label.name'), formBusiness.name, 'required')}
              </div>
              <div>
                <FormInput
                  type="text"
                  setHasChanged={setHasChanged}
                  label="form.label.coc"
                  value={formBusiness.coc}
                  onChange={(event) => {
                    const newBusiness = { ...formBusiness };
                    newBusiness.coc = event.target.value;

                    onChange('formBusiness', newBusiness, event);
                  }}
                />
                {this.validator.message(t('form.label.coc'), formBusiness.coc, 'string')}
                <FormInput
                  type="text"
                  setHasChanged={setHasChanged}
                  label="form.label.vat"
                  value={formBusiness.vat}
                  onChange={(event) => {
                    const newBusiness = { ...formBusiness };
                    newBusiness.vat = event.target.value;
                    onChange('formBusiness', newBusiness, event);
                  }}
                />
                {this.validator.message(t('form.label.vat'), formBusiness.vat, 'string')}
                <FormInput
                  type="text"
                  setHasChanged={setHasChanged}
                  label="form.label.transportLicenseNumber"
                  value={formBusiness.transportLicenseNumber}
                  onChange={(event) => {
                    const newBusiness = { ...formBusiness };
                    newBusiness.transportLicenseNumber = event.target.value;
                    onChange('formBusiness', newBusiness, event);
                  }}
                />
                {this.validator.message(
                  t('form.label.transportLicenseNumber'),
                  formBusiness.transportLicenseNumber,
                  'string'
                )}
                <FormInput
                  type="text"
                  setHasChanged={setHasChanged}
                  label="form.label.eori"
                  value={formBusiness.eori}
                  onChange={(event) => {
                    const newBusiness = { ...formBusiness };
                    newBusiness.eori = event.target.value;
                    onChange('formBusiness', newBusiness, event);
                  }}
                />
                {this.validator.message(t('form.label.eori'), formBusiness.eori, 'string')}
                <FormInput
                  type="text"
                  setHasChanged={setHasChanged}
                  label="form.label.scacCode"
                  value={formBusiness.scacCode}
                  onChange={(event) => {
                    const newBusiness = { ...formBusiness };
                    newBusiness.scacCode = event.target.value;
                    onChange('formBusiness', newBusiness, event);
                  }}
                />
                {this.validator.message(t('form.label.scacCode'), formBusiness.scacCode, 'string')}
              </div>
              <div className="input-group">
                <LanguageSelectorInput
                  value={formBusiness.preferredLanguage?.id}
                  label={t('form.label.selectPreferredLanguage')}
                  onChange={(e) => {
                    const newBusiness = { ...formBusiness };
                    newBusiness.preferredLanguage = { ...e.language };
                    onChange('formBusiness', newBusiness, e);
                  }}
                />
              </div>
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <Select
                    placeholder={`${t('form.label.selectIndustry')}`}
                    noOptionsMessage={() => t('noOptions')}
                    options={(industries || []).map((industry) => ({
                      value: industry.id,
                      label: t(`industry.${industry.name}`),
                    }))}
                    value={
                      industries
                        ? industries
                            .map((industry) => ({
                              value: industry.id,
                              label: t(`industry.${industry.name}`),
                            }))
                            .filter(
                              (industry) =>
                                industry.value ===
                                (formBusiness.industry
                                  ? formBusiness.industry.id || formBusiness.industry
                                  : null)
                            )
                        : null
                    }
                    onChange={(event) => {
                      const newBusiness = { ...formBusiness };
                      newBusiness.industry = event.value;
                      newBusiness.industryValue = event;
                      onChange('formBusiness', newBusiness, event);
                    }}
                  />
                </div>
              </div>
              <div className="input-group">
                <LocationsInput
                  key={formBusiness?.locations?.map((association) => association.entity.id).join()}
                  locations={formBusiness?.locations?.map((association) => association.entity)}
                  onChange={(newLocations) => {
                    const newBusiness = { ...formBusiness };

                    newBusiness.locations = newLocations.map(
                      (location) => new Association('inline', location)
                    );
                    onChange('formBusiness', newBusiness);
                  }}
                />
              </div>
              <FormInput
                type="textarea"
                setHasChanged={setHasChanged}
                label="form.label.description"
                value={formBusiness.description}
                onChange={(event) => {
                  const newBusiness = { ...formBusiness };
                  newBusiness.description = event.target.value;
                  onChange('formBusiness', newBusiness, event);
                }}
              />
              {this.validator.message(
                t('form.label.description'),
                formBusiness.description,
                'string'
              )}
              <FormInput
                type="text"
                setHasChanged={setHasChanged}
                label="form.label.website"
                value={formBusiness.website}
                onChange={(event) => {
                  const newBusiness = { ...formBusiness };
                  newBusiness.website = event.target.value;
                  onChange('formBusiness', newBusiness, event);
                }}
              />
              {this.validator.message(t('form.label.website'), formBusiness.website, 'string')}
            </div>
            <FlatIdentifierInput
              entityType="business"
              entity={formBusiness}
              onChange={(newIdentifiers) => {
                const newBusiness = { ...formBusiness };
                newBusiness.identifiers = newIdentifiers;
                onChange('formBusiness', newBusiness);
              }}
            />
            <div className="input-group more">
              <FormInput
                type="text"
                setHasChanged={setHasChanged}
                label="form.label.invoiceAttentionTo"
                value={formBusiness.invoiceAttentionTo}
                onChange={(event) => {
                  const newBusiness = { ...formBusiness };
                  newBusiness.invoiceAttentionTo = event.target.value;
                  onChange('formBusiness', newBusiness, event);
                }}
              />
              <FormInput
                type="text"
                setHasChanged={setHasChanged}
                label="form.label.invoiceEmail"
                value={formBusiness.invoiceEmail}
                onChange={(event) => {
                  const newBusiness = { ...formBusiness };
                  newBusiness.invoiceEmail = event.target.value;
                  onChange('formBusiness', newBusiness, event);
                }}
              />
            </div>
            <div className="input-group more">
              <FormInput
                type="text"
                setHasChanged={setHasChanged}
                label="form.label.quoteAttentionTo"
                value={formBusiness.quoteAttentionTo}
                onChange={(event) => {
                  const newBusiness = { ...formBusiness };
                  newBusiness.quoteAttentionTo = event.target.value;
                  onChange('formBusiness', newBusiness, event);
                }}
              />
              <FormInput
                type="text"
                setHasChanged={setHasChanged}
                label="form.label.quoteEmail"
                value={formBusiness.quoteEmail}
                onChange={(event) => {
                  const newBusiness = { ...formBusiness };
                  newBusiness.quoteEmail = event.target.value;
                  onChange('formBusiness', newBusiness, event);
                }}
              />
            </div>

            {platform?.features?.map((feature) => feature.name)?.includes('financial') && (
              <div className="input-group">
                <AdministrationInput
                  key={formBusiness.administration?.id || ''}
                  administration={formBusiness.administration}
                  onChange={(newAdministration) => {
                    const newBusiness = { ...formBusiness };
                    newBusiness.administration = newAdministration;

                    onChange('formBusiness', newBusiness);
                  }}
                />
              </div>
            )}
            <div className="input-group">
              <h3>{t('form.label.contactDetails')}</h3>
              <ContactDetailsInput
                key={formBusiness.contactDetails ? [...formBusiness.contactDetails] : []}
                contactDetails={formBusiness.contactDetails ? [...formBusiness.contactDetails] : []}
                onChange={(contactDetails) => {
                  const newBusiness = { ...formBusiness };
                  newBusiness.contactDetails = contactDetails;

                  onChange('formBusiness', newBusiness);
                }}
              />
            </div>
            <div className="input-group">
              <h3>{t('form.label.contactPersons')}</h3>
              <ContactPersonsInput
                key={formBusiness.contactPersons ? [...formBusiness.contactPersons] : []}
                contactPersons={formBusiness.contactPersons ? [...formBusiness.contactPersons] : []}
                onChange={(contactPersons) => {
                  const newBusiness = { ...formBusiness };
                  newBusiness.contactPersons = contactPersons;

                  onChange('formBusiness', newBusiness);
                }}
              />
            </div>
            <div className="input-group more right">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
