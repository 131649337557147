import React, { Component } from 'react';

import SimpleReactValidator from 'simple-react-validator';

import AttributesInput from '@components/attribute/AttributesInput';
import BoardComputersInput from '@components/boardComputer/crud/BoardComputersInput';
import BusinessInput from '@components/business/BusinessInput';
import NotesInput from '@components/note/crud/NotesInput';
import PricingModulesInput from '@components/pricing/crud/PricingModulesInput';

import CloseButton from '@uicomponents/CloseButton';
import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import PricingModule from '@models/pricing/PricingModule';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import DimensionsInput from '../../ui/inputs/DimensionsInput';
import ValueWithUnitInput from '../../ui/inputs/ValueWithUnitInput';
import EmissionsClassSelector from '../EmissionsClassSelector';
import FuelTypeSelector from '../FuelTypeSelector';
import VehicleTypeSelector from '../VehicleTypeSelector';

export default class VehicleForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.state = {};
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit, formVehicle, setHasChanged } = this.props;

    onSubmit && onSubmit(formVehicle);
    setHasChanged(false);
  }

  addLoadCapacity() {
    const { onChange, formVehicle } = this.props;
    const newVehicle = { ...formVehicle };
    const loadCapacities = [...newVehicle.loadCapacities, {}];

    newVehicle.loadCapacities = loadCapacities;
    onChange('formVehicle', newVehicle);
  }

  render() {
    const { platform, onChange, t, formVehicle, addToStack, setHasChanged } = this.props;

    return (
      <div className="step-form vehicle-form" key={formVehicle.id}>
        <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
          <div className="input-container">
            <div>
              <FormInput
                type="text"
                setHasChanged={setHasChanged}
                label="form.label.name"
                required={true}
                value={formVehicle.name}
                onChange={(event) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.name = event.target.value;

                  onChange('formVehicle', newVehicle, event);
                }}
              />
              {this.validator.message(t('form.label.name'), formVehicle.name, 'required')}
            </div>
            <div className="input-group vehicle-type">
              <VehicleTypeSelector
                placeholder={`${t('form.label.vehicleType')}*`}
                value={formVehicle.vehicleType}
                onChange={(type) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.vehicleType = type.value;

                  onChange('formVehicle', newVehicle);
                }}
              />
              {this.validator.message(
                t('form.label.vehicleType'),
                formVehicle.vehicleType,
                'required'
              )}
            </div>
            <div className="input-group fuel-type">
              <FuelTypeSelector
                placeholder={`${t('form.label.fuelType')}*`}
                value={formVehicle.fuelType}
                onChange={(type) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.fuelType = type.value;

                  onChange('formVehicle', newVehicle);
                }}
              />
              {this.validator.message(t('form.label.fuelType'), formVehicle.fuelType, 'required')}
            </div>
            <div className="fuel-capacity">
              <ValueWithUnitInput
                t={t}
                units={['l', 'kg']}
                label="form.label.fuelCapacity"
                value={formVehicle.fuelCapacity}
                onChange={(value) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.fuelCapacity = value;

                  onChange('formVehicle', newVehicle);
                }}
              />
            </div>
            <ValueWithUnitInput
              t={t}
              units={['ldm']}
              defaultUnit={'ldm'}
              label="form.label.loadMeters"
              value={formVehicle.loadMeters}
              onChange={(value) => {
                const newVehicle = { ...formVehicle };
                newVehicle.loadMeters = value;

                onChange('formVehicle', newVehicle);
              }}
            />
            <div className="input-group emission-type">
              <EmissionsClassSelector
                placeholder={`${t('form.label.emissionStandard')}*`}
                value={formVehicle.emissionStandard}
                onChange={(type) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.emissionStandard = type.value;

                  onChange('formVehicle', newVehicle);
                }}
              />
              {this.validator.message(
                t('form.label.emissionStandard'),
                formVehicle.emissionStandard,
                'required'
              )}
            </div>
            <div className="linked-vehicles">
              <FormInput
                type="number"
                setHasChanged={setHasChanged}
                label="form.label.maxLinks"
                required={true}
                value={formVehicle.maxLinks}
                min="0"
                onChange={(event) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.maxLinks = parseFloat(event.target.value) || 1;

                  onChange('formVehicle', newVehicle, event);
                }}
              />
              {this.validator.message(t('form.label.maxLinks'), formVehicle.maxLinks, 'required')}
            </div>
            <div className="vehicle-details">
              <FormInput
                type="text"
                setHasChanged={setHasChanged}
                label="form.label.licensePlate"
                value={formVehicle.licensePlate}
                onChange={(event) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.licensePlate = event.target.value;

                  onChange('formVehicle', newVehicle, event);
                }}
              />
              <FormInput
                type="text"
                setHasChanged={setHasChanged}
                label="form.label.vin"
                value={formVehicle.vin}
                onChange={(event) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.vin = event.target.value;

                  onChange('formVehicle', newVehicle, event);
                }}
              />
              <FormInput
                type="textarea"
                setHasChanged={setHasChanged}
                label="form.label.description"
                value={formVehicle.description}
                onChange={(event) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.description = event.target.value;

                  onChange('formVehicle', newVehicle, event);
                }}
              />
            </div>
            <div className="input-group dimensions">
              <DimensionsInput
                defaultUnit="m"
                key={formVehicle.id + formVehicle.nonce}
                unit={formVehicle.length > 0 ? formVehicle.length.unit : null}
                length={formVehicle.length}
                height={formVehicle.height}
                width={formVehicle.width}
                onChange={(e) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.length = { value: e.length, unit: e.unit };
                  newVehicle.height = { value: e.height, unit: e.unit };
                  newVehicle.width = { value: e.width, unit: e.unit };

                  onChange('formVehicle', newVehicle);
                }}
              />
            </div>
            <div className="input-group dimensions">
              <DimensionsInput
                defaultUnit="m"
                key={formVehicle.id + formVehicle.nonce}
                unit={formVehicle.loadLength > 0 ? formVehicle.loadLength.unit : null}
                lengthLabel={t('form.label.loadLength')}
                length={formVehicle.loadLength}
                heightLabel={t('form.label.loadHeight')}
                height={formVehicle.loadHeight}
                widthLabel={t('form.label.loadWidth')}
                width={formVehicle.loadWidth}
                onChange={(e) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.loadLength = { value: e.length, unit: e.unit };
                  newVehicle.loadHeight = { value: e.height, unit: e.unit };
                  newVehicle.loadWidth = { value: e.width, unit: e.unit };

                  onChange('formVehicle', newVehicle);
                }}
              />
            </div>
            <div className="input-group no-margin-top empty-weight">
              <div className="input-group no-margin-top">
                <ValueWithUnitInput
                  t={t}
                  units={['g', 'mg', 'kg', 't']}
                  label="form.label.maxWeight"
                  value={formVehicle.maxWeight}
                  onChange={(value) => {
                    const newVehicle = { ...formVehicle };
                    newVehicle.maxWeight = value;

                    onChange('formVehicle', newVehicle);
                  }}
                />
              </div>
            </div>
            <div className="input-group no-margin-top empty-weight">
              <div className="input-group no-margin-top">
                <ValueWithUnitInput
                  t={t}
                  units={['g', 'mg', 'kg', 't']}
                  label="form.label.emptyWeight"
                  value={formVehicle.emptyWeight}
                  onChange={(value) => {
                    const newVehicle = { ...formVehicle };
                    newVehicle.emptyWeight = value;

                    onChange('formVehicle', newVehicle);
                  }}
                />
              </div>
            </div>
            <div className="input-group">
              <AttributesInput
                key={formVehicle.attributes ? formVehicle.attributes : null}
                attributes={formVehicle.attributes ? formVehicle.attributes : null}
                onChange={(newAttributes) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.attributes = newAttributes ? newAttributes : null;

                  onChange('formVehicle', newVehicle);
                }}
              />
            </div>
            <div className="input-group business">
              <h3 className="no-margin-top">{t('form.label.belongsTo')}</h3>
              <BusinessInput
                key={formVehicle.business ? formVehicle.business : null}
                value={formVehicle.business ? formVehicle.business : null}
                onChange={(newBusiness) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.business = newBusiness ? { ...newBusiness } : null;

                  onChange('formVehicle', newVehicle);
                }}
              />
            </div>
            <div className="input-group board-computer">
              <h3 className="no-margin-top">{t('form.label.selectBoardComputer')}</h3>
              <BoardComputersInput
                key={formVehicle.boardComputers ? formVehicle.boardComputers : null}
                boardComputers={formVehicle.boardComputers ? formVehicle.boardComputers : null}
                onChange={(newBoardComputers) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.boardComputers = newBoardComputers ? newBoardComputers : null;

                  onChange('formVehicle', newVehicle);
                }}
              />
            </div>
            <div className="input-group">
              <h3>{t('form.label.notes')}</h3>
              <NotesInput
                notes={formVehicle.notes}
                onChange={(notes) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.notes = notes;

                  onChange('formVehicle', newVehicle);
                }}
              />
            </div>

            {platform?.features?.map((feature) => feature.name)?.includes('financial') && (
              <div className="input-group pricing">
                <h3>{t('form.label.costModules')}</h3>
                <PricingModulesInput
                  {...this.props}
                  key={formVehicle.pricingModules}
                  entityType={formVehicle.targetEntityType}
                  modules={
                    formVehicle.pricingModules
                      ? [...formVehicle.pricingModules]
                      : [new PricingModule()]
                  }
                  onChange={(newModules) => {
                    const newVehicle = { ...formVehicle };
                    newVehicle.pricingModules = newModules;

                    onChange('formVehicle', newVehicle);
                  }}
                />
              </div>
            )}
            <div className="input-group operational">
              <div>{t('form.label.operational')}</div>
              <Switch
                checked={formVehicle.operational}
                onChange={(e, newState) => {
                  const newVehicle = { ...formVehicle };
                  newVehicle.operational = newState;

                  onChange('formVehicle', newVehicle);
                }}
              />
            </div>
            <div className="input-group more right save-vehicle">
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
