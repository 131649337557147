import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import ReactSVG from 'react-svg';

import Tag from '@uicomponents/Tag';

import { swapArrayLocs } from '@utils/arrayUtils';
import { hasParentNode } from '@utils/commonUtils';
import { defaultDateTimeFormat } from '@utils/dateUtils';
import { administrativeReferenceToString } from '@utils/locationUtils';

const DragHandle = sortableHandle(() => (
  <div
    className="draggable"
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
    }}
    onDragStart={(e) => {
      console.log('draggin');
      e.stopPropagation();
      e.preventDefault();
    }}
  >
    <ReactSVG src="/icons/drag.svg" />
  </div>
));

const SortableItem = sortableElement(({ key, className, children }) => {
  return (
    <div key={key} className={className} style={{ zIndex: 1001 }}>
      {children}
    </div>
  );
});

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class TripActionBreakdown extends Component {
  static defaultProps = {
    smallView: false,
  };

  onSortEnd = ({ oldIndex, newIndex }, e) => {
    e.preventDefault();
    e.stopPropagation();

    const { trip, updateTrip } = this.props;

    let newTrip = { ...trip };
    let newActions = [...newTrip.actions];

    if (
      hasParentNode(e.target, 'planBoard__container') ||
      hasParentNode(e.target, 'trip-card') ||
      hasParentNode(e.target, 'trip-view')
    ) {
      newActions = [...swapArrayLocs(newActions, oldIndex, newIndex)]
        .map((action, index) => {
          let newAction = { ...action };
          let newEntity = { ...newAction.entity };
          newEntity.tripSequenceNr = index + 1;
          newAction.entity = newEntity;
          return newAction;
        })
        .sort((a, b) => a.tripSequenceNr - b.tripSequenceNr);
    } else {
      newActions = newActions.filter((a, index) => index !== oldIndex);
    }

    newTrip.actions = newActions;
    updateTrip?.(newTrip);
  };

  render() {
    const { t, trip, dateFormat } = this.props;
    if (!trip) return null;

    return (
      <div
        className="trip-breakdown"
        key={`trip-${trip.id}`}
        draggable={true}
        onDragStart={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className="trip-breakdown__header-row">
          {trip.status === 'accepted' && <div className="trip-breakdown__column xsmall"></div>}
          <div className="trip-breakdown__column small">{t('trip.breakdown.action')}</div>
          <div className="trip-breakdown__column two">{t('trip.breakdown.location')}</div>
          <div className="trip-breakdown__column">{t('trip.breakdown.timeWindow')}</div>
          <div className="trip-breakdown__column">{t('trip.breakdown.deliverTime')}</div>
          <div className="trip-breakdown__column">{t('trip.breakdown.takenCapacity')}</div>
          <div className="trip-breakdown__column">{t('trip.breakdown.availableCapacity')}</div>
        </div>
        <SortableContainer onSortEnd={this.onSortEnd} useDragHandle hideSortableGhost={false}>
          {trip.actions
            ?.map((association) => association.entity)
            ?.sort((a, b) => a.tripSequenceNr - b.tripSequenceNr)
            ?.map((action, index) => {
              let timeWindowStartTime = null;
              let timeWindowEndTime = null;
              timeWindowStartTime = action.constraints
                .map((constraint) => constraint.entity)
                .find((constraint) => constraint?.value?.type === 'startDateTimeConstraint');
              timeWindowEndTime = action.constraints
                .map((constraint) => constraint.entity)
                .find((constraint) => constraint?.value?.type === 'endDateTimeConstraint');

              return (
                <SortableItem
                  index={index}
                  key={action.id}
                  disabled={trip.status !== 'accepted'}
                  className={`trip-breakdown__row trip-breakdown__row--${action.lifeCycle.toLowerCase()}`}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  onDragStart={(e) => {
                    console.log('draggin');

                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  {trip.status === 'accepted' && (
                    <div
                      className="trip-breakdown__column flex-container justify-between xsmall"
                      onClick={(e) => e.preventDefault()}
                    >
                      <Tag label={index + 1} className="self-center no-padding" />
                      <DragHandle />
                    </div>
                  )}
                  <div className="trip-breakdown__column small">
                    {action.actions.map((subAction) => (
                      <Tag
                        key={subAction?.entity?.id}
                        label={subAction?.entity?.type}
                        className={subAction?.entity?.lifeCycle}
                      />
                    ))}
                  </div>
                  <div className="trip-breakdown__column two trip-breakdown__to">
                    {action.location
                      ? administrativeReferenceToString(
                          action.location.entity.administrativeReference,
                          true,
                          false,
                          action.location.entity
                        )
                      : null}
                  </div>
                  <div className="trip-breakdown__column trip-breakdown__timewindow">
                    {timeWindowStartTime &&
                      `${defaultDateTimeFormat(
                        timeWindowStartTime?.value?.startDateTime,
                        dateFormat
                      )}${timeWindowEndTime ? ' - ' : ''}`}
                    {timeWindowEndTime &&
                      defaultDateTimeFormat(timeWindowEndTime?.value?.endDateTime, dateFormat)}
                  </div>
                  <div className="trip-breakdown__column trip-breakdown__time">
                    {action.endTime ? (
                      defaultDateTimeFormat(action.endTime, dateFormat)
                    ) : action.eta ? (
                      <>
                        <b>{t('trip.eta')}</b>
                        {` - ${defaultDateTimeFormat(action.eta, dateFormat)}`}
                      </>
                    ) : (
                      t('trip.breakdown.eta.notYetCalculated')
                    )}
                  </div>
                  <div className="trip-breakdown__column trip-breakdown__capacity">
                    {action?.contextEvents?.length > 0 ? (
                      <>
                        <span>
                          <b
                            className={
                              parseFloat(
                                action.contextEvents[action.contextEvents.length - 1]
                                  ?.usedCapacityChange?.postStackObjectMetrics
                                  ?.totalMinimumGroundSpace?.value || 0
                              ) >= 0
                                ? 'green'
                                : 'red'
                            }
                          >
                            {parseFloat(
                              action.contextEvents[action.contextEvents.length - 1]
                                ?.usedCapacityChange?.postStackObjectMetrics
                                ?.totalMinimumGroundSpace?.value || 0
                            )}
                            {`m², `}
                          </b>
                          <b
                            className={
                              parseFloat(
                                action.contextEvents[action.contextEvents.length - 1]
                                  ?.usedCapacityChange?.postStackObjectMetrics?.totalVolume
                                  ?.value || 0
                              ) >= 0
                                ? 'green'
                                : 'red'
                            }
                          >
                            {parseFloat(
                              action.contextEvents[action.contextEvents.length - 1]
                                ?.usedCapacityChange?.postStackObjectMetrics?.totalVolume?.value ||
                                0
                            )}
                            {`m³, `}
                          </b>
                          <b
                            className={
                              parseFloat(
                                action.contextEvents[action.contextEvents.length - 1]
                                  ?.usedCapacityChange?.postStackObjectMetrics?.totalWeight
                                  ?.value || 0
                              ) >= 0
                                ? 'green'
                                : 'red'
                            }
                          >
                            {parseFloat(
                              action.contextEvents[action.contextEvents.length - 1]
                                ?.usedCapacityChange?.postStackObjectMetrics?.totalWeight?.value ||
                                0
                            )}
                            {`kg, `}
                          </b>
                          <b
                            className={
                              parseFloat(
                                action.contextEvents[action.contextEvents.length - 1]
                                  ?.usedCapacityChange?.postStackObjectMetrics
                                  ?.totalMinimumLoadMeters?.value || 0
                              ) >= 0
                                ? 'green'
                                : 'red'
                            }
                          >
                            {parseFloat(
                              action.contextEvents[action.contextEvents.length - 1]
                                ?.usedCapacityChange?.postStackObjectMetrics?.totalMinimumLoadMeters
                                ?.value || 0
                            )}
                            {`ldm`}
                          </b>
                        </span>
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                  <div className="trip-breakdown__column trip-breakdown__capacity">
                    {action?.contextEvents?.length > 0 ? (
                      <>
                        <span>
                          <b
                            className={
                              parseFloat(
                                action.contextEvents[action.contextEvents.length - 1]
                                  ?.availableCapacityChange?.postStackCapacityMetrics
                                  ?.availableGroundSpace?.value || 0
                              ) >= 0
                                ? 'green'
                                : 'red'
                            }
                          >
                            {parseFloat(
                              action.contextEvents[action.contextEvents.length - 1]
                                ?.availableCapacityChange?.postStackCapacityMetrics
                                ?.availableGroundSpace?.value || 0
                            )}
                            {`m², `}
                          </b>
                          <b
                            className={
                              parseFloat(
                                action.contextEvents[action.contextEvents.length - 1]
                                  ?.availableCapacityChange?.postStackCapacityMetrics
                                  ?.availableVolume?.value || 0
                              ) >= 0
                                ? 'green'
                                : 'red'
                            }
                          >
                            {parseFloat(
                              action.contextEvents[action.contextEvents.length - 1]
                                ?.availableCapacityChange?.postStackCapacityMetrics?.availableVolume
                                ?.value || 0
                            )}
                            {`m³, `}
                          </b>
                          <b
                            className={
                              parseFloat(
                                action.contextEvents[action.contextEvents.length - 1]
                                  ?.availableCapacityChange?.postStackCapacityMetrics
                                  ?.availableWeight?.value || 0
                              ) >= 0
                                ? 'green'
                                : 'red'
                            }
                          >
                            {parseFloat(
                              action.contextEvents[action.contextEvents.length - 1]
                                ?.availableCapacityChange?.postStackCapacityMetrics?.availableWeight
                                ?.value || 0
                            )}
                            {`kg, `}
                          </b>
                          <b
                            className={
                              parseFloat(
                                action.contextEvents[action.contextEvents.length - 1]
                                  ?.availableCapacityChange?.postStackCapacityMetrics
                                  ?.availableLoadMeters?.value || 0
                              ) >= 0
                                ? 'green'
                                : 'red'
                            }
                          >
                            {parseFloat(
                              action.contextEvents[action.contextEvents.length - 1]
                                ?.availableCapacityChange?.postStackCapacityMetrics
                                ?.availableLoadMeters?.value || 0
                            )}
                            {`ldm`}
                          </b>
                        </span>
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                </SortableItem>
              );
            })}
        </SortableContainer>
      </div>
    );
  }
}

export default withTranslation('translation')(TripActionBreakdown);
