import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import EntityTodosContainer from '@containers/todo/EntityTodosContainer';
import TransportOrderContainer from '@containers/transportOrder/TransportOrderContainer';

import Collapsible from '@uicomponents/Collapsible';
import FilePreview from '@uicomponents/FilePreview';
import FileViewer from '@uicomponents/FileViewer';
import LedgerTable from '@uicomponents/Ledger';
import Loader from '@uicomponents/Loader';
import MapPeek from '@uicomponents/MapPeek';
import NoData from '@uicomponents/NoData';

import { consignmentGetAllChildActions } from '@utils/actionUtils';
import { defaultDateTimeFormat } from '@utils/dateUtils';
import { administrativeReferenceToString } from '@utils/locationUtils';
import { containsPermissionPart } from '@utils/userUtils';

import ActionCards from '../action/ActionCards';
import GoodsView from '../goods/GoodsView';

class ConsignmentView extends Component {
  state = {
    filePreviewOpen: false,
    files: [],
    focussedFile: null,
  };

  componentDidMount() {
    const { displayLedger, consignment, getLedgerEntriesByEntity } = this.props;

    displayLedger &&
      getLedgerEntriesByEntity?.({ entityType: 'consignment', entityId: consignment?.id });
  }

  render() {
    const {
      t,
      platform,
      addToStack,
      consignment,
      ledgerEntries,
      isFetchingConsignment,
      notStackedFromTransportOrder,
      displayLedger,
    } = this.props;
    const { filePreviewOpen, files, focussedFile } = this.state;

    if (consignment == null || isFetchingConsignment) {
      return <Loader />;
    }

    return (
      <div className="consignment">
        <FileViewer
          t={t}
          isActive={filePreviewOpen}
          files={files}
          file={focussedFile}
          onClose={() => {
            this.setState({
              files: [],
              focussedFile: null,
              filePreviewOpen: false,
            });
          }}
        />
        <div className="information">
          {notStackedFromTransportOrder ? null : (
            <>
              <h2 className="no-margin-bottom">{consignment.name}</h2>
              <p className="no-margin">#{consignment.consignmentNr}</p>
            </>
          )}
          <div className="field-renderer">
            {consignment.transportOrder && (
              <div className="transportOrder">
                <b>{t('transportOrder.header')}: </b>
                <span
                  className="as-link"
                  onClick={(e) => {
                    addToStack({
                      name: consignment.transportOrder.name,
                      className: 'lightgray',
                      component: (
                        <TransportOrderContainer transportOrderId={consignment.transportOrder.id} />
                      ),
                    });
                  }}
                >
                  {consignment.transportOrder.orderId}
                </span>
              </div>
            )}
            <div className="description">
              <b>{t('consignment.description')}: </b>
              {consignment.description ? (
                <div>{consignment.description}</div>
              ) : (
                <NoData className="no-margin left">{t('consignment.description.notFound')}</NoData>
              )}
            </div>
            <div className="type ">
              <b>{t('consignment.type')}: </b>
              {consignment.type}
            </div>
            <div className="combined ">
              <b>{t('consignment.combined')}: </b>
              {consignment.combined ? t('yes') : t('no')}
            </div>
            {consignment.expectedRevenue && (
              <div>
                <b>{t('consignment.expectedRevenue')}</b>
                <div>
                  {consignment?.expectedRevenue?.currency?.sign || '€'}
                  {consignment?.expectedRevenue?.amount?.toFixed(2) || 0}
                </div>
              </div>
            )}

            {consignment.expectedCost && (
              <div>
                <b>{t('consignment.expectedCost')}</b>
                <div>
                  {consignment?.expectedCost?.currency?.sign || '€'}
                  {consignment?.expectedCost?.amount?.toFixed(2) || 0}
                </div>
              </div>
            )}
            {consignment?.createdAt && (
              <div>
                <b>{t('sorting.createdAt')}: </b>
                {defaultDateTimeFormat(consignment.createdAt)}
              </div>
            )}
            {consignment?.updatedAt && (
              <div>
                <b>{t('sorting.updatedAt')}: </b>
                {defaultDateTimeFormat(consignment.updatedAt)}
              </div>
            )}
            {consignment.combined ? (
              <>
                {consignment.originalPhysicalSender && (
                  <div>
                    <b>{t('consignment.originalPhysicalSender')}</b>:{' '}
                    {administrativeReferenceToString(
                      consignment.originalPhysicalSender.administrativeReference
                    )}
                    <MapPeek
                      latLonPointGeoReference={consignment.originalPhysicalSender.geoReference}
                    />
                  </div>
                )}
                {consignment.originalLegalSender && (
                  <div>
                    <b>{t('consignment.originalLegalSender')}</b>:{' '}
                    {administrativeReferenceToString(
                      consignment.originalLegalSender.administrativeReference
                    )}
                    <MapPeek
                      latLonPointGeoReference={consignment.originalLegalSender.geoReference}
                    />
                  </div>
                )}
                {consignment.originalPhysicalAddressee && (
                  <div>
                    <b>{t('consignment.originalPhysicalAddressee')}</b>:{' '}
                    {administrativeReferenceToString(
                      consignment.originalPhysicalAddressee.administrativeReference
                    )}
                    <MapPeek
                      latLonPointGeoReference={consignment.originalPhysicalAddressee.geoReference}
                    />
                  </div>
                )}
                {consignment.originalLegalAddressee && (
                  <div>
                    <b>{t('consignment.originalLegalAddressee')}</b>:{' '}
                    {administrativeReferenceToString(
                      consignment.originalLegalAddressee.administrativeReference
                    )}
                    <MapPeek
                      latLonPointGeoReference={consignment.originalLegalAddressee.geoReference}
                    />
                  </div>
                )}
              </>
            ) : null}
            <b>{t('consignment.documents')}</b>
            <div className="files">
              {consignment?.documents.length > 0 ? (
                consignment.documents.map((attachment) => (
                  <div
                    key={`attachment-${attachment.entity.id}`}
                    className="file"
                    onClick={() => {
                      this.setState({
                        filePreviewOpen: true,
                        files: consignment.documents.map((document) => document.entity.file),
                        focussedFile: attachment.entity.file,
                      });
                    }}
                  >
                    <FilePreview
                      file={attachment.entity.file}
                      isDocVerified={attachment.entity?.verified}
                    />
                  </div>
                ))
              ) : (
                <NoData className="left no-margin">{t('consignment.documents.notFound')}</NoData>
              )}
            </div>
          </div>

          <b>{t('consignment.actions')}</b>
          {consignment.actions.length > 0 && (
            <ActionCards
              {...this.props}
              active={false}
              actions={[
                ...consignment.actions,
                ...(consignmentGetAllChildActions(consignment) || []),
              ]}
            />
          )}
        </div>
        <div className="goods">
          {consignment.goods
            .map((association) => association.entity)
            .filter((entity) => entity.type === 'items')
            .map((entity, index) => (
              <Collapsible
                key={entity.id}
                name={`${t('goods.header', { count: index + 1 })} ${
                  entity.name ? ` - ${entity.quantity}x ${entity.name}` : ''
                }`}
                className="collapsible-element reversed"
                defaultIsOpen={false}
                childStyle={{ padding: '10px 10px 10px 40px' }}
              >
                <GoodsView key={entity.id} goods={entity} />
              </Collapsible>
            ))}
        </div>
        <div className="goods">
          {consignment.goods
            .map((association) => association.entity)
            .filter((entity) => entity.type === 'transportEquipment')
            .map((entity, index) => (
              <Collapsible
                key={entity.id}
                name={`${t('transportEquipment.header', {
                  count: index + 1,
                })} ${entity.name ? ` - ${entity.name}` : ''}`}
                className="collapsible-element reversed"
                defaultIsOpen={false}
                childStyle={{ padding: '10px 10px 10px 40px' }}
              >
                <GoodsView
                  key={entity.id}
                  goods={entity}
                  simplifiedView={this.props.simplifiedView}
                />
              </Collapsible>
            ))}
        </div>
        {platform?.features?.filter((feature) => feature.name === 'todo').length > 0 && (
          <Collapsible
            name={t('todo.entityView.title')}
            className="collapsible-element"
            defaultIsOpen={false}
          >
            <EntityTodosContainer
              {...this.props}
              relatedEntity={consignment}
              relatedEntityType="consignment"
            />
          </Collapsible>
        )}
        {displayLedger && containsPermissionPart('ledger') && (
          <Collapsible name={t('ledger')} className="collapsible-element" defaultIsOpen={false}>
            <div className="my-10">
              <LedgerTable
                t={t}
                key={consignment.id}
                entityType={'consignment'}
                data={ledgerEntries || []}
              />
            </div>
          </Collapsible>
        )}
      </div>
    );
  }
}

export default withTranslation('translation')(ConsignmentView);
