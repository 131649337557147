import React, { Component, Fragment, Suspense, lazy } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import { MapContainer, Marker, Polyline } from 'react-leaflet';

import L, { divIcon, latLngBounds } from 'leaflet';
import moment from 'moment';
import PolyUtil from 'polyline-encoded';

import ActionCards from '@components/action/ActionCards';
import EntityTodosContainer from '@containers/todo/EntityTodosContainer';
import VehicleDetailContainer from '@containers/vehicle/VehicleDetailContainer';

import FileViewer from '@uicomponents/FileViewer';
import LedgerTable from '@uicomponents/Ledger';
import Loader from '@uicomponents/Loader';
import PopOver from '@uicomponents/PopOver';
import BigVehicleMarker from '@uimarker/default/BigVehicleMarker';
import TileLayers from '@uiviews/TileLayers';

import { getLocationUpdateEvents } from '@api/vehicleApi';

import { swapArrayLocs } from '@utils/arrayUtils';
import { defaultDateTimeFormat, momentToDuration } from '@utils/dateUtils';
import { containsPermissionPart } from '@utils/userUtils';

import TripActionBreakdown from './TripActionBreakdown';
import TripBreakdown from './TripBreakdown';
import TripExpenses from './TripExpenses';

const DocumentsContainer = lazy(() => import('@containers/document/DocumentsContainer'));

export default class TripContent extends Component {
  static defaultProps = {
    isPublic: false,
    tabbedView: false,

    standalone: false,
  };

  state = {
    finishCheckPopOver: false,
    startNextTrip: false,
    tab: 'information',
    events: [],
    eventsIsFetching: false,
  };

  componentDidMount() {
    const { trip, getLedgerEntriesByEntity } = this.props;

    containsPermissionPart('ledger') &&
      getLedgerEntriesByEntity({ entityType: 'trip', entityId: trip?.id });

    if (trip?.vehicle?.entity && trip.status === 'in_transit') {
      this.setState({ eventsIsFetching: true });
      getLocationUpdateEvents(
        trip.vehicle.entity.id,
        trip.startDate ? moment(trip.startDate) : moment(),
        trip.endDate ? moment(trip.endDate) : moment()
      ).then((response) => {
        const events = response.events;
        this.setState({
          eventsIsFetching: false,
          events,
        });
      });
    }
  }

  render() {
    const {
      t,
      user,
      trip,
      trips,
      isPublic,
      tabbedView,
      standalone,
      updateTrip,
      confirmTrip,
      startTrip,
      finishTrip,
      finishAndStartNewTrip,
      latLonPointGeoReference,
      dateFormat,
      addToStack,
      ledgerEntries,
    } = this.props;
    const { finishCheckPopOver, filePreviewOpen, files, focussedFile, events } = this.state;

    const tab = this.props.tab ? this.props.tab : this.state.tab;

    const moveActions = trip?.route?.actions
      ? trip.route.actions
          .map((association) => association.entity)
          .filter((entity) => entity.type === 'move')
          .sort((a, b) => a.sequenceNr - b.sequenceNr)
      : [];

    const sortedActions = trip?.actions
      ?.map((association) => association.entity)
      .sort((a, b) => a.tripSequenceNr - b.tripSequenceNr);

    const latLonArrayPointGeoReference = [
      ...trip?.route?.lineString
        ?.split(',')
        ?.filter((s) => s)
        ?.map((lineString) => [...PolyUtil.decode(lineString)]),
    ];

    const bounds = latLngBounds(
      latLonArrayPointGeoReference?.length > 0
        ? latLonArrayPointGeoReference
        : latLonPointGeoReference
        ? [
            [latLonPointGeoReference.lat - 0.005, latLonPointGeoReference.lon - 0.005],
            [latLonPointGeoReference.lat + 0.005, latLonPointGeoReference.lon + 0.005],
          ]
        : [[0, 0]]
    );

    const isActive = trip.status === 'in_transit';

    return (
      <>
        <FileViewer
          t={t}
          isActive={filePreviewOpen}
          files={files}
          file={focussedFile}
          onClose={() => {
            this.setState({
              files: [],
              focussedFile: null,
              filePreviewOpen: false,
            });
          }}
        />
        <PopOver
          isActive={finishCheckPopOver}
          className="select-pop-over"
          onClose={() => {
            this.setState({
              finishCheckPopOver: false,
            });
          }}
        >
          <div className="box">
            <div className="title">{t('trip.finish.sure.header')}</div>
            <div className="text">{t('trip.finish.sure.description')}</div>

            <div className="choice">
              <button
                href=""
                onClick={(e) => {
                  e.preventDefault();

                  this.setState({
                    finishCheckPopOver: false,
                  });
                }}
              >
                {t('no')}
              </button>
              <div
                className="as-link"
                onClick={(e) => {
                  e.preventDefault();

                  const { trip, startNextTrip } = this.state;

                  if (startNextTrip) {
                    finishAndStartNewTrip(trip, trips[1]);
                  } else {
                    finishTrip(trip);
                  }

                  this.setState({
                    finishCheckPopOver: false,
                    startNextTrip: false,
                  });
                }}
              >
                {t('yes')}
              </div>
            </div>
          </div>
        </PopOver>
        {!tabbedView && (
          <ScrollContainer
            verticle={false}
            hideScrollbars={false}
            className="align-items-center d-flex gap-10 tab-links-container my-1 white small"
          >
            <div
              className={`tab-links smaller dark${tab === 'information' ? ' tab-active' : ''}`}
              onClick={() => this.setState({ tab: 'information' })}
            >
              {t('trip.tabs.information')}
            </div>
            <div
              className={`tab-links smaller dark${tab === 'actions' ? ' tab-active' : ''}`}
              onClick={() => this.setState({ tab: 'actions' })}
            >
              {t('trip.tabs.actions')}
            </div>
            <div
              className={`tab-links smaller dark${tab === 'action-breakdown' ? ' tab-active' : ''}`}
              onClick={() => this.setState({ tab: 'action-breakdown' })}
            >
              {t('trip.tabs.action-breakdown')}
            </div>
            <div
              className={`tab-links smaller dark${tab === 'route-breakdown' ? ' tab-active' : ''}`}
              onClick={() => this.setState({ tab: 'route-breakdown' })}
            >
              {t('trip.tabs.route-breakdown')}
            </div>
            {trip.booking && (
              <div
                className={`tab-links smaller dark${tab === 'booking' ? ' tab-active' : ''}`}
                onClick={() => this.setState({ tab: 'booking' })}
              >
                {t('trip.tabs.booking')}
              </div>
            )}
            <div
              className={`tab-links smaller dark${tab === 'documents' ? ' tab-active' : ''}`}
              onClick={() => this.setState({ tab: 'documents' })}
            >
              {t('documents.header')}
            </div>
            {!isPublic && (
              <>
                {containsPermissionPart('ledger') && (
                  <div
                    className={`tab-links smaller dark${tab === 'ledger' ? ' tab-active' : ''}`}
                    onClick={() => this.setState({ tab: 'ledger' })}
                  >
                    {t('ledger')}
                  </div>
                )}
                {this.props.platform?.features?.filter((feature) => feature.name === 'todo')
                  .length > 0 && (
                  <div
                    className={`tab-links smaller dark${tab === 'todo' ? ' tab-active' : ''}`}
                    onClick={() => this.setState({ tab: 'todo' })}
                  >
                    {t('todos.header')}
                  </div>
                )}
              </>
            )}
          </ScrollContainer>
        )}
        {tab === 'information' && (
          <>
            <div className="flex-container justify-between"></div>
            <div className="trip-info">
              <div className="trip-info__block trip-info__status">
                <div className="trip-info__label">{t('trip.status')}</div>
                <span className={`trip-status trip-status--${trip.status}`}>{trip.status}</span>
              </div>
              <div className="trip-info__block trip-info__distance">
                <div className="trip-info__label">{t('trip.distance')}</div>
                <div>{Math.round(trip.route.distance / 1000)}KM</div>
              </div>

              <div className="trip-info__block trip-info__time">
                <div className="trip-info__label">{t('trip.duration')}</div>
                <div>{momentToDuration(moment.duration(trip.route.time, 'seconds'))}</div>
              </div>
              <div className="trip-info__block">
                <div className="trip-info__label">{t('trip.linkedTrip')}</div>
                <div>{trip.linkedTrip ? t('yes') : t('no')}</div>
              </div>
              {trip.startDate && (
                <div className="trip-info__block trip-info__startTime">
                  <div className="trip-info__label">{t('form.label.startTime')}</div>
                  <div>{defaultDateTimeFormat(trip.startDate, dateFormat)}</div>
                </div>
              )}

              {trip.endDate && (
                <div className="trip-info__block trip-endTime">
                  <div className="trip-info__label">{t('form.label.endTime')}</div>
                  <div>{defaultDateTimeFormat(trip.endDate, dateFormat)}</div>
                </div>
              )}
              {trip.expectedRevenue && (
                <div className="trip-info__block trip-info__startTime">
                  <div className="trip-info__label">{t('trip.expectedRevenue')}</div>
                  <div>
                    {trip?.expectedRevenue?.currency?.sign || '€'}
                    {trip?.expectedRevenue?.amount || 0}
                  </div>
                </div>
              )}

              {trip.expectedCost && (
                <div className="trip-info__block trip-endTime">
                  <div className="trip-info__label">{t('trip.expectedCost')}</div>
                  <div>
                    {trip?.expectedCost?.currency?.sign || '€'}
                    {trip?.expectedCost?.amount || 0}
                  </div>
                </div>
              )}
              {trip.vehicle && (
                <div className="trip-info__block trip-endTime">
                  <div className="trip-info__label">{t('trip.vehicle')}</div>
                  <div
                    className="as-link"
                    onClick={(e) => {
                      e.preventDefault();

                      addToStack?.({
                        name: trip.vehicle.entity.name,
                        className: 'lightgray',
                        component: <VehicleDetailContainer vehicleId={trip.vehicle.entity.id} />,
                      });
                    }}
                  >
                    {trip.vehicle.entity.name || trip.vehicle.entity.licensePlate}
                  </div>
                </div>
              )}
              {trip.chauffeurs.length > 0 && (
                <div className="trip-info__block trip-endTime">
                  <div className="trip-info__label">{t('planBoard.chauffeurNames')}</div>
                  {trip.chauffeurs.map((chauffeur) => (
                    <div
                      className="as-link"
                      onClick={(e) => {
                        e.preventDefault();

                        addToStack?.({
                          name: `${chauffeur.firstName} ${chauffeur.lastName}`,
                          className: 'lightgray',
                          component: <ChauffeurContainer chauffeurId={chauffeur.id} />,
                        });
                      }}
                    >
                      {chauffeur.firstName} {chauffeur.lastName}
                    </div>
                  ))}
                </div>
              )}
              {trip.CO2eWTW && (
                <div className="trip-info__block trip-info__distance">
                  <div className="trip-info__label">{t('trip.co2')}</div>
                  <div>{trip.CO2eWTW}g</div>
                </div>
              )}
            </div>
          </>
        )}

        {!standalone && (tab === 'map' || tab === 'information' || tab === 'route-breakdown') && (
          <MapContainer
            className="vehicle-trips__map"
            preferCanvas={false}
            zoomControl={false}
            attributionControl={false}
            boundsOptions={{ padding: [35, 35] }}
            bounds={bounds}
          >
            <TileLayers />
            {latLonPointGeoReference && (
              <Marker
                key={`${latLonPointGeoReference.id}`}
                icon={BigVehicleMarker}
                position={[latLonPointGeoReference.lat, latLonPointGeoReference.lon]}
                rotationOrigin="center"
                rotationAngle={-latLonPointGeoReference.heading?.value || 0}
              />
            )}

            {sortedActions?.map((action, actionIndex) => (
              <Marker
                key={`${action.id}`}
                icon={divIcon({
                  html: `
                  <svg x="0px" y="0px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" version="1.1">
                  <g>
                    <path id="path" d="m255,-1c-102.245,0 -185.427,83.182 -185.427,185.426c0,126.888 165.939,313.167 173.004,321.035c6.636,7.391 18.222,7.378 24.846,0c7.065,-7.868 173.004,-194.147 173.004,-321.035c-0.002,-102.244 -83.183,-185.426 -185.427,-185.426z"/>
                    <text transform="matrix(1 0 0 1 0 0)" xml:space="preserve" text-anchor="start" font-size="325" font-style="bold" stroke-width="0" id="number" y="345" x="170">${
                      actionIndex + 1
                    }</text>
                  </g>
                </svg>
                    `,
                  iconSize: new L.Point(32, 32),
                  iconAnchor: [16, 32],
                  popupAnchor: null,
                  shadowUrl: null,
                  shadowSize: null,
                  shadowAnchor: null,
                  className: 'number-marker',
                })}
                position={[
                  action.location?.entity.geoReference.lat,
                  action.location?.entity.geoReference.lon,
                ]}
              />
            ))}

            {moveActions.reverse().map((moveAction) => {
              const encodedLineString = moveAction?.route?.entity?.lineString;

              return encodedLineString ? (
                <Fragment key={`moveAction-${moveAction?.id}`}>
                  {encodedLineString
                    ?.split(',')
                    ?.filter((s) => s)
                    ?.map((lineString) => (
                      <Polyline
                        key={`moveAction-${moveAction?.id}`}
                        positions={PolyUtil.decode(lineString)}
                        color={moveAction.lifeCycle === 'actual' ? 'red' : 'green'}
                      />
                    ))}
                </Fragment>
              ) : null;
            })}
            {events?.length > 0 && (
              <Polyline
                positions={events.map((point) => [point.geoReference.lat, point.geoReference.lon])}
                color={'black'}
                dashedArray={[12, 12]}
              />
            )}
          </MapContainer>
        )}
        {tab === 'actions' && (
          <ActionCards
            tripSequence={true}
            key={trip.id - trip.updatedAt}
            actions={trip.actions}
            moveActions={trip.route.actions}
            active={isActive}
            trip={trip}
            onParentActionSortEnd={(oldIndex, newIndex) => {
              let newTrip = { ...trip };
              let newActions = [...newTrip.actions];

              const { updateTrip } = this.props;

              newActions = [...swapArrayLocs(newActions, oldIndex, newIndex)]
                .map((action, index) => {
                  let newAction = { ...action };
                  let newEntity = { ...newAction.entity };
                  newEntity.tripSequenceNr = index + 1;
                  newAction.entity = newEntity;
                  return newAction;
                })
                .sort((a, b) => a.tripSequenceNr - b.tripSequenceNr);

              newTrip.actions = newActions;
              updateTrip?.(newTrip);
            }}
          />
        )}
        {tab === 'documents' && (
          <>
            <Suspense fallback={<>{<Loader />}</>}>
              <DocumentsContainer
                entity={trip}
                entityType="trip"
                onChange={(newTrip) => {
                  const { updateTrip } = this.props;

                  updateTrip(newTrip);
                }}
                callback={(newTrip) => {
                  changeValue('trip', newTrip || {});
                }}
              />
            </Suspense>
          </>
        )}
        {tab === 'ledger' && (
          <div className="ledger mt-20">
            <h2 className="no-margin-bottom">{t('trip.expenses')}</h2>
            <TripExpenses user={user} expenses={trip.route.expenses} />
            <h2>{t('ledger')}</h2>
            <LedgerTable t={t} key={trip.id} entityType={'trip'} data={ledgerEntries} />
          </div>
        )}
        {tab === 'route-breakdown' && (
          <>
            <TripBreakdown trip={trip} dateFormat={dateFormat} />
          </>
        )}
        {(tab === 'information' || tab === 'action-breakdown') && (
          <>
            <TripActionBreakdown trip={trip} dateFormat={dateFormat} updateTrip={updateTrip} />
          </>
        )}
        {tab === 'todo' && (
          <>
            {console.log('showing')}
            <h2>{t('todos.header')}</h2>
            <EntityTodosContainer {...this.props} relatedEntity={trip} relatedEntityType="trip" />
          </>
        )}
        {!isPublic && (
          <section>
            <div className="actions-bar mt-10">
              {(trip.status === 'accepted' || trip.status === 'requested') &&
                trip.vehicle !== null && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      confirmTrip(trip);
                    }}
                  >
                    {t('trip.confirm')}
                  </button>
                )}
              {trip.status === 'confirmed' && !trips.find((t) => t.status === 'in_transit') && (
                <button
                  onClick={(e) => {
                    e.preventDefault();

                    startTrip(trip);
                  }}
                >
                  {t('trip.start')}
                </button>
              )}
              {isActive && (
                <div className={`${trips.length > 1 ? 'buttons' : ''}`}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();

                      this.setState({
                        finishCheckPopOver: true,
                        trip: trip,
                      });
                    }}
                  >
                    {t('trip.finish')}
                  </button>
                  {trips.length > 1 && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();

                        this.setState({
                          finishCheckPopOver: true,
                          startNextTrip: true,
                          trip: trip,
                        });
                      }}
                    >
                      {t('trip.finishAndStartNext')}
                    </button>
                  )}
                </div>
              )}
            </div>
          </section>
        )}
      </>
    );
  }
}
